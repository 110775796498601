<template>
  <div class="table-css">
    <el-table class="background-shadow"
              :height="tableHeight"
              :row-class-name="tableRowClassName"
              v-bind="{
        data: data.list,
        ...tableConfig,
      }"
              v-on="{
        'selection-change': handleSelectionChange,
        ...tableFun,
      }">
      <!-- :height="
        data.list && (data.list.length > 10 || data.list.length === 0)
          ? '700px'
          : ''
      "
      style="width: 100%" -->
      <slot name="isFirst"></slot>
      <slot name="sort"></slot>
      <!-- <el-table-column
        type="selection"
        width="55"
        v-if="tableColumnList[0] && tableColumnList[0].type === 'selection'"
      ></el-table-column> -->
      <el-table-column :show-overflow-tooltip="true"
                       v-for="item of tableColumnList"
                       :key="item.prop"
                       v-bind="{
          'show-overflow-tooltip': true,
          align: 'left',
          prop: item.prop,
          label: item.label,
          ...item.config,
        }">
        <!-- v-if="item.type !== 'selection'" -->
        <template slot-scope="scope">
          <span v-bind="{
              style: {
                color: '#409EFF',
                cursor: 'pointer',
                'text-decoration': 'underline',
                ...item.dataStyle,
              },
            }"
                v-if="item.click"
                @click="item.click(scope)">{{ scope.row[item.prop] }}</span>
          <span v-bind="{
              style: {
                ...item.dataStyle,
              },
            }"
                v-if="!item.click">{{
              item.type === "time"
                ? getTime(scope.row[item.prop])
                : scope.row[item.prop]
            }}</span>
        </template>
      </el-table-column>
      <slot name="last"></slot>
      <slot name="operating"></slot>
      <!-- <el-table-column align="center" fixed="right" label="操作" width="150" v-if="!hideOperating">
                <template slot-scope="scope">
                    <el-button v-if="rate" @click.native.prevent="showRate(scope)" type="text">查看费率</el-button>
                    <el-button @click.native.prevent="showRow(scope)" type="text">查看</el-button>
                    <el-button @click.native.prevent="edit(scope)" type="text">编辑</el-button>
                    <el-button @click.native.prevent="del(scope)" type="text">删除</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    <div class="totalText">共 {{paginationConfig.total}} 条</div>
    <el-pagination v-if="!hidePagination"
                   class="pagination"
                   v-bind="{
        'page-size': map.pageSize,
        'page-sizes': [15, 50, 200, 500],
        total: data.total || data.list.length,
        layout: 'total, sizes, prev, pager, next, jumper',
        'current-page': map.pageNum,
        background: true,
        ...paginationConfig,
      }"
                   v-on="{
        'size-change': sizeChange,
        'current-change': ($event) => changePage($event),
        ...paginationFun,
      }"></el-pagination>
  </div>
</template>
<script>
export default {
  name: "dtTable",
  data () {
    return {
      checkList: [],
    };
  },
  props: {
    data: {
      default: () => { },
      type: Object,
    },
    hideOperating: {
      default: false,
      type: Boolean,
    },
    map: {
      default: () => { },
      type: Object,
    },
    tableColumnList: {
      default: () => [],
      type: Array,
    },
    tableConfig: {
      default: () => { },
      type: Object,
    },
    tableFun: {
      default: () => { },
      type: Object,
    },
    paginationConfig: {
      default: () => { },
      type: Object,
    },
    paginationFun: {
      default: () => { },
      type: Object,
    },
    hidePagination: {
      default: false,
      type: Boolean,
    },
    tableHeight: {
      type: Number
    }
  },
  components: {},
  computed: {},
  watch: {},
  created () { },
  mounted () {
    // console.log(this.data, "Table处的Table处的Table处的Table处的Table处的");
  },
  methods: {
    getTime (time) {
      return time ? new Date(time).Format("yyyy-MM-dd hh:mm:ss") : "";
    },
    showRow (scope) {
      console.log(scope, "scopescopescopescope");
      this.$emit("showRow", scope);
    },
    clickChange (scope) {
      this.$emit("clickChange", scope);
    },
    edit (scope) {
      this.$emit("edit", scope);
    },
    del (scope) {
      this.$emit("del", scope);
    },
    sizeChange (pageSize) {
      this.map.pageSize = pageSize;
      this.map.pageNum = 1;
      this.$emit("getList", this.map);
    },
    changePage (pageNum) {
      this.map.pageNum = pageNum;
      this.$emit("getList", this.map);
    },
    handleSelectionChange (list) {
      this.checkList = list;
    },
    // 斑马线
    tableRowClassName (rows) {
      if (rows.rowIndex % 2 === 0) {
        return 'double-row';
      } else {
        return 'odd-row'
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-css {
  width: 100%;

  .pagination {
    float: right;
    margin: 20px 0;
  }
  .totalText {
    display: inline-block;
    height: 68px;
    line-height: 68px;
    // margin: 20px 0;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    @include font_color('paginationFontColor');
    // color: #70aee8;
  }
}
/deep/ .el-table .double-row {
  // background: #091d3c !important;
  @include background('tableSingularLine');
}
/deep/ .odd-row {
  // background: #002444 !important;
  @include background('tabledualLine');
}
</style>