<!--
 * @Description:人行道 运营管理 异常离场 abnormalDeparture
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-06-16 :35:52
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select v-model="searchForm.parkId"
                         clearable
                         filterable
                         placeholder="全部">
                <el-option v-for="item in parkNameList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌颜色">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.numberPlateColorCode"
                         placeholder="全部">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="8">
            <el-form-item label-width="90px"
                          label="离场时间">
              <el-date-picker v-model="leaveTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>异常离场</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">
          导 出
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  :config='tableConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8"
                          v-if="scope.row.isChained!==1">
                    <el-button @click="handleClickEdit(scope.row)"
                               type="text"
                               size="medium">改单</el-button>
                  </el-col>
                  <el-col :span="8"
                          v-if="scope.row.isChained!==1">
                    <el-button @click="handleClickBindOrder(scope.row)"
                               type="text"
                               size="medium">绑定订单</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 详情弹窗 -->
    <el-dialog title="订单详情"
               :visible.sync="watchListDialog"
               :append-to-body="true"
               :destroy-on-close="true"
               close-on-click-modal
               width="45%">
      <div style="display:flex">
        <!-- 弹窗左侧 -->
        <el-row style="margin-top:25px;">
          <el-row style="margin-top:20px">
            <span style="color:white;font-size:19px">{{watchFormList.plateNumber}}</span>
          </el-row>
          <el-row style="margin-top:40px">
            <span :span="8"
                  class="span-title">车牌颜色:</span>
            <span :span="16"
                  class="span-text">{{watchFormList.plateColor}}</span>
          </el-row>
          <el-row style="margin-top:40px">
            <span :span="8"
                  class="span-title">离场时间:</span>
            <span :span="16"
                  class="span-text">{{watchFormList.leaveTime}}</span>
          </el-row>
          <el-row style="margin-top:40px">
            <span :span="8"
                  class="span-title">所属停车场:</span>
            <span :span="16"
                  class="span-text">{{watchFormList.parkName}}</span>
          </el-row>
        </el-row>
        <!-- 右侧 -->
        <el-row style="height: 340px;width: 230px; margin-left:100px; border: 1px solid rgb(15, 145, 222); ">
          <el-image style="width:100%;height:100%"
                    v-if="watchFormList.evidenceImgUrl !==undefined"
                    :src="$imgBaseUrl + watchFormList.evidenceImgUrl"
                    :preview-src-list="outsrcList"
                    fit="fill">
            <el-row slot="error"
                    style="height:100%;text-align:center">
              <el-row style="height:100%;margin-top:145px;color:white">图片加载失败</el-row>
            </el-row>
          </el-image>
        </el-row>
      </div>
    </el-dialog>
    <!-- 改单弹窗 -->
    <el-dialog title="改单"
               :visible.sync="editDialogVisible"
               append-to-body>
      <el-form :model="editForm"
               :rules="rules"
               ref="form">
        <el-row>
          <el-col :span=12>
            <el-form-item label="车牌号"
                          label-width="95px"
                          prop="plateNumber">
              <el-input v-model="editForm.plateNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="车牌颜色"
                          label-width="95px"
                          prop="numberPlateColorCode">
              <el-select clearable
                         v-model="editForm.plateColor"
                         @change="handleChangePlateColor"
                         placeholder="请选择">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-row type="flex"
                justify="end">
          <el-col :span="3">
            <el-button type="primary"
                       @click="formSubmit">确 定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="editDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 请选择需要补充离场的订单 -->
    <el-dialog title="请选择需要补充离场的订单"
               :visible.sync="bindDialogVisible"
               append-to-body
               width="60%">
      <el-form :inline="true"
               :model="searchFormIn">
        <el-row>
          <el-col :span=8>
            <el-form-item label="车牌号"
                          label-width="95px">
              <el-input v-model="searchFormIn.plateNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span=8>
            <el-form-item label="车牌颜色"
                          label-width="95px">
              <el-select clearable
                         v-model="searchFormIn.plateColor">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  style="display:flex">
            <el-button type="primary"
                       @click="dialogSelect">查询</el-button>
            <el-button type="info"
                       @click="dialogCancle">清空</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 表格 -->
      <el-table ref="multipleTable"
                style="width: 100%;margin-top: 10px;"
                border
                :data="tableDataIn"
                @select="select"
                @row-click="rowClick"
                @selection-change="selectionChange">
        <el-table-column type="selection"
                         width="50">
        </el-table-column>
        <el-table-column show-overflow-tooltip
                         prop="orderSequence"
                         label="订单编号">
        </el-table-column>
        <el-table-column show-overflow-tooltip
                         prop="plateNumber"
                         label="车牌号">
        </el-table-column>
        <el-table-column show-overflow-tooltip
                         prop="plateColor"
                         label="车牌颜色">
        </el-table-column>
        <el-table-column show-overflow-tooltip
                         prop="entranceTime"
                         label="入场时间">
        </el-table-column>
      </el-table>
      <div style="margin-top:10px">
        <span class="total">共 {{totalIn}} 条</span>
        <el-pagination class="pagination"
                       background
                       layout="prev,pager,next,jumper"
                       :page-size="pageSizeIn"
                       :total="totalIn"
                       :current-page="pageNumIn"
                       @current-change="getListIn"></el-pagination>
      </div>
      <div slot="footer"
           class="dialog-footer">
        <el-row type="flex"
                justify="end">
          <el-col :span="3">
            <el-button type="primary"
                       @click="bindSubmit">确 定</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="bindDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      //表格设置
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15 },
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'plateColor',
          label: '车牌颜色',
        },
        {
          prop: 'leaveTime',
          label: '离场时间',
        },
        {
          prop: 'isChainedName',
          label: '是否绑定订单',
        },
      ],
      tableList: { list: [] },
      // 查询参数
      searchForm: {},
      pageNum: 1,
      pageSize: 15,

      watchListDialog: false,
      watchFormList: {},
      outsrcList: [],

      editForm: {},
      editDialogVisible: false,

      bindDialogVisible: false,
      tableDataIn: [],
      searchFormIn: {},
      totalIn: 0,
      pageNumIn: 1,
      pageSizeIn: 8,
      selectData: [],
      lineRowIn: {},
      lineRowId: "",

      rules: {
        plateNumber: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        numberPlateColorCode: [{ required: true, message: '请选择车牌颜色', trigger: 'change' }],
      },

      parkNameList: [],// 停车场
      colorList: [], // 车牌颜色
      alterColor: [],
      leaveTime: [],

      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkList()
    this.queryPlateColorList()
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.post("/cqparkApi/collection/abnormalCarOut/exportAbnormalCarOut", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "异常离场.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 获取数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$abnormalDeparture.queryAbnormalCarOutList(this.searchForm).then(res => {
        res.resultEntity.list.forEach(item => {
          if (item.isChained === 0) {
            item.isChainedName = '否'
          } else if (item.isChained === 1) {
            item.isChainedName = '是'
          }
        })
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    onSubmit () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.leaveTime) {
        this.searchForm.abnormalCarOutStartTime = this.leaveTime[0]
        this.searchForm.abnormalCarOutEndTime = this.leaveTime[1]
      } else {
        this.searchForm.abnormalCarOutStartTime = ''
        this.searchForm.abnormalCarOutEndTime = ''
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.leaveTime = null
      this.searchForm = {}
      this.queryTableList()
    },
    // 点击查看详情
    handleClickDetail (row) {
      this.outsrcList = []
      let info = {
        id: row.id
      }
      this.$abnormalDeparture.queryAbnormalCarOutDetail(info).then(res => {
        this.watchFormList = res.resultEntity
        this.outsrcList.push(this.$imgBaseUrl + this.watchFormList.evidenceImgUrl)
        this.watchListDialog = true
      })
    },
    // 绑定订单
    handleClickBindOrder (row) {
      this.parkId = ''
      this.leaveTime = ''
      this.lineRowId = ''
      this.parkId = row.parkId
      this.leaveTime = row.leaveTime
      this.lineRowId = row.id
      this.pageNumIn = 1
      this.dialogSearch()
      this.bindDialogVisible = true
    },
    // 绑定默认查询表格
    dialogSearch () {
      this.lineRowIn = {}
      this.searchFormIn['parkId'] = this.parkId
      this.searchFormIn['pageNum'] = this.pageNumIn
      this.searchFormIn['pageSize'] = this.pageSizeIn
      this.searchFormIn['leaveTime'] = this.leaveTime
      this.$abnormalDeparture.findOrderByParkIdAndCondition(this.searchFormIn).then(res => {
        this.tableDataIn = res.resultEntity.content
        this.totalIn = res.resultEntity.totalElements
      })
    },
    // 绑定分页
    getListIn (change) {
      this.pageNumIn = change
      this.lineRowIn = {}
      this.dialogSearch()
    },
    // 绑定查询
    dialogSelect () {
      this.pageNumIn = 1
      this.lineRowIn = {}
      this.dialogSearch()
    },
    // 绑定清空
    dialogCancle () {
      this.pageNumIn = 1
      this.searchFormIn = {}
      this.lineRowIn = {}
      this.dialogSearch()
    },
    // 当用户手动勾选数据行的时触发的事件
    select (selection, row) {
      this.$refs.multipleTable.clearSelection()
      if (selection.length === 0) {
        this.lineRowIn = {}
        return
      }
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 表格某一行的单击事件
    rowClick (row) {
      this.$refs.multipleTable.clearSelection()
      if (this.selectData.length === 1) {
        this.selectData.forEach(item => {
          if (item == row) {
            this.$refs.multipleTable.toggleRowSelection(row, false);
            this.lineRowIn = {}
          } else {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        })
      } else {
        this.$refs.multipleTable.toggleRowSelection(row, false);
        this.lineRowIn = {}
      }
    },
    // 表格的选中 可以获得当前选中的数据
    selectionChange (val) {
      this.selectData = val
      if (this.selectData.length === 1) {
        this.lineRowIn = this.selectData[0]
      } else if (this.selectData.length === 2) {
        this.lineRowIn = this.selectData[1]
      }
    },
    // 绑定提交
    bindSubmit () {
      // 未选中有内容 / 有选中判断
      if (Object.keys(this.lineRowIn).length === 0) {
        this.$message({ message: "请选择", type: 'warning' })
      } else {
        let info = {
          id: this.lineRowId,
          orderSequence: this.lineRowIn.orderSequence
        }
        this.$abnormalDeparture.bindAbnormalCarOutToOrder(info).then(() => {
          this.$message({ message: "绑定成功", type: 'success' })
          this.queryTableList()
          this.bindDialogVisible = false
        })
      }
    },
    // 改单
    handleClickEdit (row) {
      this.editForm = row
      this.editDialogVisible = true
    },
    // 改单中的颜色发生变化
    handleChangePlateColor (val) {
      this.alterColor = this.colorList.filter(item => {
        if (item.code == val) {
          return item
        }
      })
    },
    // 改单提交
    formSubmit () {
      if (this.alterColor.length !== 0) {
        this.editForm.numberPlateColorCode = Number(this.alterColor[0].code)
        this.editForm.plateColor = this.alterColor[0].name
      }
      let info = {
        id: this.editForm.id,
        plateNumber: this.editForm.plateNumber,
        plateColor: this.editForm.plateColor
      }
      this.$abnormalDeparture.updateAbnormalCarOut(info).then(() => {
        this.$message({ showClose: true, message: '修改成功', type: 'success' });
        this.editDialogVisible = false
        this.queryTableList()
      })
    },
    // 停车场
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
        whereStr: [
          {
            "colName": "park_type_code",
            "value": "2"
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 获取车牌颜色
    queryPlateColorList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.colorList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.total {
  margin-left: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #70aee8;
  line-height: 30px;
}
.pagination {
  float: right;
  margin: 0px 10px;
}
/deep/ .el-table-column--selection.is-leaf.el-table__cell {
  .el-checkbox__inner {
    display: none !important;
  }
}
.span-title {
  color: #79baf7;
  font-size: 16px;
}
.span-text {
  color: #789db7;
  font-size: 16px;
}
</style>
